import React from "react";

function NerdHobbies() {
  return (
    <React.Fragment>
      <div>Nerd Hobbies</div>
    </React.Fragment>
  );
}

export default NerdHobbies;
