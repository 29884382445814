import React from "react";
import Resume from "./Christopher Bast - Full Stack Software Engineer.pdf";
import "../../App.css";
import { Button } from "react-bootstrap";

function CV() {
  return (
    <div className="cv">
      {/* <Button variant="primary">
        <a href={Resume} download className="btn">
          Download Resume
        </a>
      </Button> */}
      <Button variant="success">
        <a href="/contact" className="btn">
          Contact Me
        </a>
      </Button>
    </div>
  );
}

export default CV;
