import React from "react";
import { SocialIcon } from "react-social-icons";
import { Container } from "react-bootstrap";

function Footer() {
  return (
    <React.Fragment>
      <Container fluid align="center" className="social-media footer">
        <SocialIcon
          className="social-media-link"
          url="https://www.facebook.com/profile.php?id=100041691227338"
          target="_blank"
          rel="noreferrer"
          size="xs"
        />
        <SocialIcon
          className="social-media-link"
          url="https://www.instagram.com/chris.hollywood.bast/?hl=en"
          target="_blank"
          rel="noreferrer"
        />
        <SocialIcon
          className="social-media-link"
          url="https://www.linkedin.com/in/christopher-bast-969962183/"
          target="_blank"
          rel="noreferrer"
        />
        <SocialIcon
          className="social-media-link"
          url="https://github.com/cdbast"
          target="_blank"
          rel="noreferrer"
        />
        <div className="copyright">
          © 2023, Designed and Created By Christopher Bast
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Footer;
