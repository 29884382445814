import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "../../App.css";

function NavBar() {
  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        bg="dark"
        variant="dark"
        sticky="top"
        className="navbar-font"
      >
        <Container fluid>
          <Navbar.Brand href="/">Home</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link href="/ohthreeseries">The Oh-Three Series</Nav.Link>
              <Nav.Link href="/milsimwest">MilSim West</Nav.Link>
              {/* <Nav.Link href="/nerdhobbies">Nerd Hobbies</Nav.Link> */}
              <Nav.Link href="/contact">Contact Me</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
}

export default NavBar;
