import React from "react";
import profile from "../../images/profile.jpg";
import "../../App.css";
import { Image, Container, Row, Col } from "react-bootstrap";
import migrately from "../../images/migrately-logo-large.png";
import CV from "./Resume";

function Home() {
  return (
    <React.Fragment>
      <div className="App-header">
        <Container align="center">
          <Image
            src={profile}
            alt="profile"
            roundedCircle
            className="profile-pic"
          />
        </Container>
        <Container className="about-me" align="center">
          <h1 className="name">Christopher Bast</h1>
          <h3 className="work">Full Stack Software Developer</h3>
          <p className="about-me-summary">
            Hey there! I'm a United States Marine Corps veteran turned Software
            Developer. My biggest take away from the military was understanding
            commander's intent and then communicating that with a team to find
            the most efficient solution to overcome any obstacle. I'm never too
            proud to say "I don't know" and am always willing to learn and
            listen to anyone in the room. I enjoy working with a team and
            building comradery. I see code as a puzzle and enjoy trying to solve
            it. I also have many side hobbies including writing, airsoft, and
            table-top wargaming. Please take a look around, check out my books,
            consider MilSim West, and contact me if you're interested in
            connecting!
          </p>
          <CV />
          <Row>
            <h4 className="name">Languages & Experience</h4>
          </Row>
          <Row>
            <Col>
              <p className="languages">React</p>
            </Col>
            <Col>
              <p className="languages">HTML</p>
            </Col>
            <Col>
              <p className="languages">CSS</p>
            </Col>
            <Col>
              <p className="languages">Javascript</p>
            </Col>
            <Col>
              <p className="languages">.Net/C#</p>
            </Col>
            <Col>
              <p className="languages">SQL</p>
            </Col>
          </Row>
          <Row>
            <p className="exp">1 Year of Software Development:</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://migrately.azurewebsites.net/"
            >
              <Image src={migrately} className="migrately"></Image>
            </a>
            <p className="about-me-summary">
              Migrately simplifies the U.S. Visa and immigration journey
              combining the power of community and technology
            </p>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Home;
