import React, { useRef } from "react";
import emailjs from "emailjs-com";
import { MdOutlineEmail } from "react-icons/md";
import "./contact.css";
import { Container, Button } from "react-bootstrap";
import Swal from "sweetalert2";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wy133pf",
        "template_uu4fose",
        form.current,
        "GYZJf-C4sl-z7sMuN"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();

    Swal.fire("Thanks for reaching out!", "Email Sent", "success");
  };

  return (
    <React.Fragment>
      <Container className="container.contact_container">
        <Container className="contact_option">
          <h2 className="title">Get in Touch</h2>
          <h3 className="subtitle">
            <MdOutlineEmail />
            email
          </h3>
          {/* <h4 className="subtitle">chris.d.bast@gmail.com</h4> */}
        </Container>
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <Button variant="primary" type="submit" className="title">
            Send Message
          </Button>
        </form>
      </Container>
    </React.Fragment>
  );
}

export default Contact;
