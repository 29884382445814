import React from "react";
import { Button, Row, Container } from "react-bootstrap";
import "./milsimwest.css";

function MilSimWest() {
  return (
    <React.Fragment>
      <div className="background">
        <Row>
          <p className="milsim-west-title" align="center">
            MilSim West
          </p>
        </Row>
        <Row>
          <p className="milsim-west-about" align="center">
            The premier military simulation event host in the United States and
            I'm fortunate enough to call it a home and family.
          </p>
        </Row>
        <Container align="center">
          <p className="milsim-west-about">
            At MSW I get to wear many hats such as...
          </p>
          <Row>
            <p className="milsim-west-roles">Cadre</p>
            <p className="milsim-west-roles">Manager</p>
            <p className="milsim-west-roles">Recruiter</p>
          </Row>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://milsimwest.com"
          >
            <Button variant="secondary">Check Us Out</Button>
          </a>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default MilSimWest;
