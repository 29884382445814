import React from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import "./ohthreeseries.css";
import OhThreeElevenCover from "../../images/0311_KindleCoverTitle_v01.jpg";
import OhThreeThirtyOne from "../../images/0331_KindleCoverTitle_v02.jpg";
import OhThreeFortyOneCover from "../../images/0341_CoverTitle_v03.jpg";
import EightyFourOhFourCover from "../../images/8404_CoverTitle_v02_KINDLE.jpg";
import OhThreeThirteenCover from "../../images/0313_CoverTitle_v01_Kindle.jpg";
import OhThreeFiftyOneCover from "../../images/0351_CoverTitle_v01.jpg";
import OhThreeOhTwoCover from "../../images/0302_CoverTitle_v01.jpg";
import OhThreeFiftyTwoCover from "../../images/0352_CoverTitleKindle_v01.jpg";
import OhThreeSeventeenCover from "../../images/0317_CoverTitle_v01.jpg";
import OhThreeSixNineCover from "../../images/0369_CoverTitle_v01_KINDLE (1).jpg";
import OhThreeTwentyOneCover from "../../images/0321_KindleCoverTitle_v01.jpg";

function OhThreeSeries() {
  return (
    <React.Fragment>
      <div align="center">
        <h1 className="title">The Oh Three Series</h1>
        <p className="about">
          The Oh-Three Series is a compilation of military fiction novellas
          based on the United States Marine Corps' infantry based military
          occupational specialties during Operation Enduring Freedom in
          Afghanistan. Each book is a standalone story and can be read in any
          order. Read them all to unravel the fight against the Taliban around
          Combat Outpost Javelin.
        </p>
      </div>
      <Container fluid className="justify-content-center deployment-pic">
        <Row>
          <Col>
            <h4 className="book-titles" align="center">
              BOOK 1
            </h4>
            <Row>
              <div align="center">
                <p className="book-mos">RIFLEMAN</p>
              </div>
            </Row>
            <div align="center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amazon.com/Oh-Three-Eleven-Oh-Three-Christopher-Bast/dp/1726895920/ref=sr_1_3?crid=29L9PQPHNL49N&keywords=christopher+bast&qid=1672869953&sprefix=%2Caps%2C115&sr=8-3"
              >
                <Image src={OhThreeElevenCover} className="book-covers"></Image>
              </a>
            </div>
          </Col>
          <Col>
            <h4 className="book-titles" align="center">
              BOOK 2
            </h4>
            <Row>
              <div align="center">
                <p className="book-mos">MACHINEGUNNER</p>
              </div>
            </Row>
            <div align="center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amazon.com/Oh-Three-Thirty-One-Oh-Three-Christopher-Bast/dp/1730897568/ref=sr_1_9?crid=29L9PQPHNL49N&keywords=christopher+bast&qid=1672869953&sprefix=%2Caps%2C115&sr=8-9"
              >
                <Image src={OhThreeThirtyOne} className="book-covers"></Image>
              </a>
            </div>
          </Col>
          <Col>
            <h4 className="book-titles" align="center">
              BOOK 3
            </h4>
            <Row>
              <div align="center">
                <p className="book-mos">MORTARMAN</p>
              </div>
            </Row>
            <div align="center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amazon.com/Oh-Three-Forty-One-Oh-Three-Christopher-Bast/dp/1793332819/ref=sr_1_1?crid=1VQXO60IZYG15&keywords=oh+three+forty+one+christopher+bast&qid=1672870070&sprefix=oh+three+forty+one+christopher+bas%2Caps%2C136&sr=8-1"
              >
                <Image
                  src={OhThreeFortyOneCover}
                  className="book-covers"
                ></Image>
              </a>
            </div>
          </Col>
          <Col>
            <h4 className="book-titles" align="center">
              BOOK 4
            </h4>
            <Row>
              <div align="center">
                <p className="book-mos">CORPSMAN "DOC"</p>
              </div>
            </Row>
            <div align="center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amazon.com/Eighty-Four-Oh-Four-Oh-Three-Christopher-Bast/dp/1090156766/ref=d_zg-te-pba_sccl_2_2/139-3759419-3166828?pd_rd_w=LNoPP&content-id=amzn1.sym.081392b0-c07f-4fc2-8965-84d15d431f0d&pf_rd_p=081392b0-c07f-4fc2-8965-84d15d431f0d&pf_rd_r=NF0ZNKZQMXPRT2FXWNZ5&pd_rd_wg=nxHT6&pd_rd_r=125e75e8-f3e4-4df8-bffb-3bd3d7263f65&pd_rd_i=1090156766&psc=1"
              >
                <Image
                  src={EightyFourOhFourCover}
                  className="book-covers"
                ></Image>
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="book-titles" align="center">
              BOOK 5
            </h4>
            <Row>
              <div align="center">
                <p className="book-mos">LAV CREWMAN</p>
              </div>
            </Row>
            <div align="center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amazon.com/Oh-Three-Thirteen-Oh-Three-Christopher-Bast/dp/1094889768/ref=pd_bxgy_img_sccl_2/139-3759419-3166828?pd_rd_w=YpfRd&content-id=amzn1.sym.7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_p=7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_r=WY0BTYVSS5QXS8GFXYAZ&pd_rd_wg=IcmeA&pd_rd_r=e50999b8-b15e-4d3f-8088-4d13ff46edff&pd_rd_i=1094889768&psc=1"
              >
                <Image
                  src={OhThreeThirteenCover}
                  className="book-covers"
                ></Image>
              </a>
            </div>
          </Col>
          <Col>
            <h4 className="book-titles" align="center">
              BOOK 6
            </h4>
            <Row>
              <div align="center">
                <p className="book-mos">ASSAULTMAN</p>
              </div>
            </Row>
            <div align="center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amazon.com/Oh-Three-Fifty-One-Oh-Three-Christopher-Bast/dp/1687551898/ref=d_zg-te-pba_sccl_3_6/139-3759419-3166828?pd_rd_w=iADLI&content-id=amzn1.sym.081392b0-c07f-4fc2-8965-84d15d431f0d&pf_rd_p=081392b0-c07f-4fc2-8965-84d15d431f0d&pf_rd_r=7AVAT6Y8YPKW6J87F8C0&pd_rd_wg=EUl0b&pd_rd_r=6315b067-ac99-4260-a70a-165631d5cdef&pd_rd_i=1687551898&psc=1"
              >
                <Image
                  src={OhThreeFiftyOneCover}
                  className="book-covers"
                ></Image>
              </a>
            </div>
          </Col>
          <Col>
            <h4 className="book-titles" align="center">
              BOOK 7
            </h4>
            <Row>
              <div align="center">
                <p className="book-mos">INFANTRY OFFICER</p>
              </div>
            </Row>
            <div align="center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amazon.com/Oh-Three-Oh-Two-Oh-Three-Christopher-Bast/dp/1701903229/ref=d_zg-te-pba_sccl_2_5/139-3759419-3166828?pd_rd_w=7Gpw9&content-id=amzn1.sym.081392b0-c07f-4fc2-8965-84d15d431f0d&pf_rd_p=081392b0-c07f-4fc2-8965-84d15d431f0d&pf_rd_r=S5S1HPRW11XXGB9QEJYD&pd_rd_wg=XH2mq&pd_rd_r=a46e9f64-dbcb-4d84-99b7-d127de7ba0a3&pd_rd_i=1701903229&psc=1"
              >
                <Image src={OhThreeOhTwoCover} className="book-covers"></Image>
              </a>
            </div>
          </Col>
          <Col>
            <h4 className="book-titles" align="center">
              BOOK 8
            </h4>
            <Row>
              <div align="center">
                <p className="book-mos">ANTITANK MISSILEMAN</p>
              </div>
            </Row>
            <div align="center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amazon.com/Oh-Three-Fifty-Two-Christopher-Bast/dp/B08H57YYK6/ref=pd_bxgy_img_sccl_2/139-3759419-3166828?pd_rd_w=Sywjn&content-id=amzn1.sym.7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_p=7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_r=CHXQDCHQB283M3QXKHFP&pd_rd_wg=CsFU4&pd_rd_r=81b997ad-f717-4b4e-828a-0c14d115b06f&pd_rd_i=B08H57YYK6&psc=1"
              >
                <Image
                  src={OhThreeFiftyTwoCover}
                  className="book-covers"
                ></Image>
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="book-titles" align="center">
              BOOK 9
            </h4>
            <Row>
              <div align="center">
                <p className="book-mos">SCOUT SNIPER</p>
              </div>
            </Row>
            <div align="center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amazon.com/Oh-Three-Seventeen-Christopher-Bast/dp/B08LQTM2KH/ref=sr_1_1?crid=1THVAMIYVTYZV&keywords=oh+three+seventeen+christopher+bast&qid=1672870241&s=books&sprefix=oh+three+seventeen+christopher+bas%2Cstripbooks%2C122&sr=1-1"
              >
                <Image
                  src={OhThreeSeventeenCover}
                  className="book-covers"
                ></Image>
              </a>
            </div>
          </Col>
          <Col>
            <h4 className="book-titles" align="center">
              BOOK 10
            </h4>
            <Row>
              <div align="center">
                <p className="book-mos">INFANTRY UNIT LEADER</p>
              </div>
            </Row>
            <div align="center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amazon.com/Oh-Three-Six-Nine-Christopher-Bast/dp/B0BW2PPSKD/ref=sr_1_1?crid=1BONEFCXFFCNT&keywords=oh+three+six+nine+christopher+bast&qid=1680565557&sprefix=oh+three+six+nine+christopher+bast%2Caps%2C150&sr=8-1"
              >
                <Image
                  src={OhThreeSixNineCover}
                  className="book-covers"
                ></Image>
              </a>
            </div>
          </Col>
          <Col>
            <h4 className="book-titles" align="center">
              BOOK 11
            </h4>
            <Row>
              <div align="center">
                <p className="book-mos">RECONNAISSANCE MARINE</p>
              </div>
            </Row>
            <div align="center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amazon.com/Oh-Three-Twenty-One-Christopher-Bast/dp/B0BW32R576/ref=sr_1_1?crid=FSNPV6Q60ALT&keywords=oh+three+twenty+one+christopher+bast&qid=1680565584&sprefix=oh+three+twenty+on+christopher+bast%2Caps%2C124&sr=8-1"
              >
                <Image
                  src={OhThreeTwentyOneCover}
                  className="book-covers"
                ></Image>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <div align="center">
        <h1 className="title">About the Author</h1>
        <p className="about">
          Christopher Bast is a combat veteran of the United States Marine Corps
          having served as an 0311 Rifleman and Scout for the 1st Light Armored
          Reconnaissance Battalion out of Camp Pendleton, California. He
          deployed to Afghanistan in 2010, then again in 2011-12. He would leave
          the Marine Corps in 2013 with an honorable discharge at the rank of
          Corporal.
        </p>
      </div>
    </React.Fragment>
  );
}

export default OhThreeSeries;
