import React from "react";
import "./App.css";
import NavBar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Home from "./components/home/Home";
import MilSimWest from "./components/milsimwest/MilSimWest";
import OhThreeSeries from "./components/ohthreeseries/OhThreeSeries";
import NerdHobbies from "./components/nerdhobbies/NerdHobbies";
import Contact from "./components/contact/Contact";

function App() {
  const Paths = () => {
    let routes = useRoutes([
      { path: "", element: <Home /> },
      { path: "/milsimwest", element: <MilSimWest /> },
      { path: "/ohthreeseries", element: <OhThreeSeries /> },
      { path: "/nerdhobbies", element: <NerdHobbies /> },
      { path: "/contact", element: <Contact /> },
    ]);
    return routes;
  };
  return (
    <React.Fragment>
      <NavBar />
      <Router>
        <Paths />
      </Router>
      <Footer />
    </React.Fragment>
  );
}

export default App;
